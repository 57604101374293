import { surveyLocalization } from "../surveyStrings";

export var portugueseSurveyStrings = {
  pagePrevText: "Anterior",
  pageNextText: "Próximo",
  completeText: "Finalizar",
  previewText: "Pré-visualização",
  editText: "Editar",
  startSurveyText: "Começar",
  otherItemText: "Outros (descrever)",
  noneItemText: "Nenhum",
  selectAllItemText: "Selecionar Todos",
  progressText: "Página {0} de {1}",
  panelDynamicProgressText: "Registro {0} de {1}",
  questionsProgressText: "Respostas {0}/{1} perguntas",
  emptySurvey: "Não há página visível ou pergunta na pesquisa.",
  completingSurvey: "Obrigado por finalizar a pesquisa!",
  completingSurveyBefore:
    "Nossos registros mostram que você já finalizou a pesquisa.",
  loadingSurvey: "A pesquisa está carregando...",
  optionsCaption: "Selecione...",
  value: "valor",
  requiredError: "Por favor, responda a pergunta.",
  requiredErrorInPanel: "Por favor, responda pelo menos uma pergunta.",
  requiredInAllRowsError:
    "Por favor, responda as perguntas em todas as linhas.",
  numericError: "O valor deve ser numérico.",
  textMinLength: "Por favor, insira pelo menos {0} caracteres.",
  textMaxLength: "Por favor, insira menos de {0} caracteres.",
  textMinMaxLength: "Por favor, insira mais de {0} e menos de {1} caracteres.",
  minRowCountError: "Preencha pelo menos {0} linhas.",
  minSelectError: "Selecione pelo menos {0} opções.",
  maxSelectError: "Por favor, selecione não mais do que {0} opções.",
  numericMinMax:
    "O '{0}' deve ser igual ou superior a {1} e igual ou menor que {2}",
  numericMin: "O '{0}' deve ser igual ou superior a {1}",
  numericMax: "O '{0}' deve ser igual ou inferior a {1}",
  invalidEmail: "Por favor, informe um e-mail válido.",
  invalidExpression: "A expressão: {0} deve retornar 'verdadeiro'.",
  urlRequestError: "A requisição retornou o erro '{0}'. {1}",
  urlGetChoicesError:
    "A requisição não retornou dados ou o 'caminho' da requisição não está correto",
  exceedMaxSize: "O tamanho do arquivo não deve exceder {0}.",
  otherRequiredError: "Por favor, informe o outro valor.",
  uploadingFile:
    "Seu arquivo está sendo carregado. Por favor, aguarde alguns segundos e tente novamente.",
  loadingFile: "Carregando...",
  chooseFile: "Selecione o(s) arquivo(s)...",
  noFileChosen: "Nenhum ficheiro escolhido",
  confirmDelete: "Tem certeza que deseja deletar?",
  keyDuplicationError: "Esse valor deve ser único.",
  addColumn: "Adicionar coluna",
  addRow: "Adicionar linha",
  removeRow: "Remover linha",
  addPanel: "Adicionar novo",
  removePanel: "Remover",
  choices_Item: "item",
  matrix_column: "Coluna",
  matrix_row: "Linha",
  savingData: "Os resultados esto sendo salvos no servidor...",
  savingDataError: "Ocorreu um erro e não foi possível salvar os resultados.",
  savingDataSuccess: "Os resultados foram salvos com sucesso!",
  saveAgainButton: "Tente novamente",
  timerMin: "min",
  timerSec: "seg",
  timerSpentAll: "Você gastou {0} nesta página e {1} no total.",
  timerSpentPage: "Você gastou {0} nesta página.",
  timerSpentSurvey: "Você gastou {0} no total.",
  timerLimitAll: "Você gastou {0} de {1} nesta página e {2} de {3} no total.",
  timerLimitPage: "Você gastou {0} de {1} nesta página.",
  timerLimitSurvey: "Você gastou {0} de {1} no total.",
  cleanCaption: "Limpar",
  clearCaption: "Limpar",
  chooseFileCaption: "Escolher ficheiro",
  removeFileCaption: "Remover este arquivo",
  booleanCheckedLabel: "Sim",
  booleanUncheckedLabel: "Não",
  confirmRemoveFile: "Tem certeza que deseja remover este arquivo: {0}?",
  confirmRemoveAllFiles: "Tem certeza que deseja remover todos os arquivos?",
  questionTitlePatternText: "Título da questão",
};

surveyLocalization.locales["pt"] = portugueseSurveyStrings;
surveyLocalization.localeNames["pt"] = "português";
