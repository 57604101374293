export enum MessageActionType {
  SEND_MESSAGE_COMMAND = 'pubnub/SEND_MESSAGE_COMMAND',
  FETCH_MESSAGE_HISTORY_COMMAND = 'pubnub/FETCH_MESSAGE_HISTORY_COMMAND',
  MESSAGE_RECEIVED = 'pubnub/MESSAGE_RECEIVED',
  SENDING_MESSAGE = 'pubnub/SENDING_MESSAGE',
  MESSAGE_SENT = 'pubnub/MESSAGE_SENT',
  ERROR_SENDING_MESSAGE = 'pubnub/ERROR_SENDING_MESSAGE',
  FETCHING_MESSAGE_HISTORY = 'pubnub/FETCHING_MESSAGE_HISTORY',
  MESSAGE_HISTORY_RETRIEVED = 'pubnub/MESSAGE_HISTORY_RETRIEVED',
  ERROR_FETCHING_MESSAGE_HISTORY = 'pubnub/ERROR_FETCHING_MESSAGE_HISTORY',
}
