import { surveyLocalization } from "../surveyStrings";

export var welshSurveyStrings = {
  pagePrevText: "Blaenorol",
  pageNextText: "Nesaf",
  completeText: "Cwblhau",
  previewText: "Rhagolwg",
  editText: "Golygu",
  startSurveyText: "Dechrau",
  otherItemText: "Arall (disgrifiwch)",
  noneItemText: "Dim",
  selectAllItemText: "Dewis y Cyfan ",
  progressText: "Tudalen {0} o {1}",
  panelDynamicProgressText: "Cofnod {0} o {1}",
  questionsProgressText: "Wedi ateb {0}/{1} cwestiwn",
  emptySurvey: "Does dim modd gweld tudalen na chwestiwn yn yr arolwg.",
  completingSurvey: "Diolch am lenwi’r holiadur!",
  completingSurveyBefore: "Rydych chi wedi llenwi’r arolwg hwn yn barod yn ôl ein cofnodion.",
  loadingSurvey: "Wrthi’n Llwytho’r Arolwg...",
  optionsCaption: "Dewiswch...",
  value: "gwerth",
  requiredError: "Atebwch y cwestiwn.",
  requiredErrorInPanel: "Atebwch o leiaf un cwestiwn.",
  requiredInAllRowsError: "Atebwch y cwestiynau ym mhob rhes.",
  numericError: "Dylai’r gwerth fod yn rhif.",
  textMinLength: "Rhowch o leiaf {0} nod.",
  textMaxLength: "Rhowch lai na {0} nod.",
  textMinMaxLength: "Rhowch o leiaf {0} nod ond dim mwy na {1}.",
  minRowCountError: "Llenwch o leiaf {0} rhes.",
  minSelectError: "Dewiswch o leiaf {0} amrywiolyn.",
  maxSelectError: "Peidiwch â dewis mwy na {0} amrywiolyn.",
  numericMinMax: "Dylai’r '{0}' fod yr un fath â {1} neu’n fwy, a’r fath â {2} neu’n llai",
  numericMin: "Dylai’r '{0}' fod yr un fath â {1} neu’n fwy",
  numericMax: "Dylai’r '{0}' fod yr un fath â {1} neu’n llai",
  invalidEmail: "Rhowch gyfeiriad e-bost dilys.",
  invalidExpression: "Dylai’r mynegiad {0} arwain at 'true'.",
  urlRequestError: "Roedd y cais wedi arwain at y gwall '{0}'. {1}",
  urlGetChoicesError: "Roedd y cais wedi arwain at ddata gwag neu mae priodwedd y ‘path’ yn anghywir ",
  exceedMaxSize: "Ddylai’r ffeil ddim bod yn fwy na {0}.",
  otherRequiredError: "Rhowch y gwerth arall.",
  uploadingFile: "Mae eich ffeil wrthi’n llwytho i fyny. Arhoswch ychydig o eiliadau a rhoi cynnig arall arni.",
  loadingFile: "Wrthi’n llwytho...",
  chooseFile: "Dewiswch ffeil(iau)...",
  noFileChosen: "Heb ddewis ffeil ",
  confirmDelete: "Ydych chi am ddileu’r cofnod?",
  keyDuplicationError: "Dylai’r gwerth hwn fod yn unigryw.",
  addColumn: "Ychwanegu colofn ",
  addRow: "Ychwanegu rhes",
  removeRow: "Tynnu",
  addPanel: "Ychwanegu o’r newydd",
  removePanel: "Tynnu",
  choices_Item: "eitem",
  matrix_column: "Colofn",
  matrix_row: "Rhes",
  savingData: "Mae’r canlyniadau’n cael eu cadw ar y gweinydd...",
  savingDataError: "Roedd gwall a doedd dim modd cadw’r canlyniadau.",
  savingDataSuccess: "Wedi llwyddo i gadw’r canlyniadau!",
  saveAgainButton: "Rhowch gynnig arall arni",
  timerMin: "mun",
  timerSec: "eil",
  timerSpentAll: "Rydych chi wedi treulio {0} ar y dudalen hon a {1} gyda’i gilydd.",
  timerSpentPage: "Rydych chi wedi treulio {0} ar y dudalen hon.",
  timerSpentSurvey: "Rydych chi wedi treulio {0} gyda’i gilydd.",
  timerLimitAll: "Rydych chi wedi treulio {0} o {1} ar y dudalen hon a {2} o {3} gyda’i gilydd.",
  timerLimitPage: "Rydych chi wedi treulio {0} o {1} ar y dudalen hon.",
  timerLimitSurvey: "Rydych chi wedi treulio {0} o {1} gyda’i gilydd.",
  cleanCaption: "Glanhau",
  clearCaption: "Clirio",
  chooseFileCaption: "Dewiswch ffeil ",
  removeFileCaption: "Tynnu’r ffeil hon ",
  booleanCheckedLabel: "Iawn",
  booleanUncheckedLabel: "Na",
  confirmRemoveFile: "Ydych chi’n siŵr eich bod am dynnu’r ffeil hon: {0}?",
  confirmRemoveAllFiles: "Ydych chi’n siŵr eich bod am dynnu pob ffeil?",
  questionTitlePatternText: "Teitl y Cwestiwn ",
};

surveyLocalization.locales["cy"] = welshSurveyStrings;
surveyLocalization.localeNames["cy"] = "cymraeg";
