export enum ErrorStatusActionType {
  NETWORK_ISSUES_EVENT = 'pubnub/NETWORK_ISSUES_EVENT',
  ACCESS_DENIED_EVENT = 'pubnub/ACCESS_DENIED_EVENT',
  MALFORMED_RESPONSE_EVENT = 'pubnub/MALFORMED_RESPONSE_EVENT',
  BAD_REQUEST_EVENT = 'pubnub/BAD_REQUEST_EVENT',
  DECRYPTION_ERROR_EVENT = 'pubnub/DECRYPTION_ERROR_EVENT',
  TIMEOUT_CONNECTION_EVENT = 'pubnub/TIMEOUT_CONNECTION_EVENT',
  REQUEST_MESSAGE_COUNT_EXCEED_EVENT = 'pubnub/REQUEST_MESSAGE_COUNT_EXCEED_EVENT',
  UNKNOWN_EVENT = 'pubnub/UNKNOWN_EVENT',
}
