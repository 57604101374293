export enum MembershipActionType {
  // fetchMemberships()
  FETCH_MEMBERSHIPS_COMMAND = 'pubnub/FETCH_MEMBERSHIPS_COMMAND',
  FETCHING_MEMBERSHIPS = 'pubnub/FETCHING_MEMBERSHIPS',
  MEMBERSHIPS_RETRIEVED = 'pubnub/MEMBERSHIPS_RETRIEVED',
  ERROR_FETCHING_MEMBERSHIPS = 'pubnub/ERROR_FETCHING_MEMBERSHIPS',
  // setMemberships()
  SET_MEMBERSHIPS_COMMAND = 'pubnub/SET_MEMBERSHIPS_COMMAND',
  SETTING_MEMBERSHIPS = 'pubnub/SETTING_MEMBERSHIPS',
  MEMBERSHIPS_SET = 'pubnub/MEMBERSHIPS_SET',
  ERROR_SETTING_MEMBERSHIPS = 'pubnub/ERROR_SETTING_MEMBERSHIPS',
  // removeMemberships()
  REMOVE_MEMBERSHIPS_COMMAND = 'pubnub/REMOVE_MEMBERSHIPS_COMMAND',
  REMOVING_MEMBERSHIPS = 'pubnub/REMOVING_MEMBERSHIPS',
  MEMBERSHIPS_REMOVED = 'pubnub/MEMBERSHIPS_REMOVED',
  ERROR_REMOVING_MEMBERSHIPS = 'pubnub/ERROR_REMOVING_MEMBERSHIPS',
  // objects events
  MEMBERSHIP_REMOVED_EVENT = 'pubnub/MEMBERSHIP_REMOVED_EVENT',
  MEMBERSHIP_SET_EVENT = 'pubnub/MEMBERSHIP_SET_EVENT',
}
