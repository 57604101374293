export enum ChannelMembersActionType {
  // fetchChannelMembers()
  FETCH_CHANNEL_MEMBERS_COMMAND = 'pubnub/FETCH_CHANNEL_MEMBERS_COMMAND',
  FETCHING_CHANNEL_MEMBERS = 'pubnub/FETCHING_CHANNEL_MEMBERS',
  CHANNEL_MEMBERS_RETRIEVED = 'pubnub/CHANNEL_MEMBERS_RETRIEVED',
  ERROR_FETCHING_CHANNEL_MEMBERS = 'pubnub/ERROR_FETCHING_CHANNEL_MEMBERS',
  // setChannelMembers()
  SET_CHANNEL_MEMBERS_COMMAND = 'pubnub/SET_CHANNEL_MEMBERS_COMMAND',
  SETTING_CHANNEL_MEMBERS = 'pubnub/SETTING_CHANNEL_MEMBERS',
  CHANNEL_MEMBERS_SET = 'pubnub/CHANNEL_MEMBERS_SET',
  ERROR_SETTING_CHANNEL_MEMBERS = 'pubnub/ERROR_SETTING_CHANNEL_MEMBERS',
  // removeChannelMembers()
  REMOVE_CHANNEL_MEMBERS_COMMAND = 'pubnub/REMOVE_CHANNEL_MEMBERS_COMMAND',
  REMOVING_CHANNEL_MEMBERS = 'pubnub/REMOVING_CHANNEL_MEMBERS',
  CHANNEL_MEMBERS_REMOVED = 'pubnub/CHANNEL_MEMBERS_REMOVED',
  ERROR_REMOVING_CHANNEL_MEMBERS = 'pubnub/ERROR_REMOVING_CHANNEL_MEMBERS',
}
